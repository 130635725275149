import React from "react"

class Benificial extends React.Component {

  render() {
    return (
      <section className="fine-box fintech pt-100 mb-0 pb-3">
          <div className="gray-bg">
            <div className="container"> 
              <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits Of </span>Our Fintech Software Development Services</h2>
              <p className="text-center mb-2">
              We offer cutting-edge fintech software development solutions that allow you to gain the attention of worldwide users.
              </p>
              <div className="d-lg-flex flex-wrap add-one">
                <div className="square" >
                  <h3>Enhanced Services</h3>
                  <p className="pharagraph">
                  Our developer team offers you the best and most enhanced development services to streamline a digital financial process. Our fintech services offer you with seamless transaction handling, convenience, and personalized services.
                  </p>
                </div>
                <div className="square" >
                  <h3>Security Protocol Integration</h3>
                  <p className="pharagraph">
                  To ensure secure transactions, our technical team implements advanced-level security plugins and protocols. This helps you to safeguard the user data, transaction details, and financial information securely.
                  </p>
                </div>
                <div className="square" >
                  <h3>Regulatory Compliance</h3>
                  <p className="pharagraph">
                  As per the geographical location and region, we take proper measures for regulating the legal norms & conditions. We mitigate compliance-related risks and challenges to implement the Fintech project seamlessly.
                  </p>
                </div>
                <div className="square" >
                  <h3>Automation</h3>
                  <p className="pharagraph">
                  Our Fintech solutions enable self-executing financial transactions that eradicate the need for third parties and intermediaries. This paves the way to increase accuracy and reduces the amount of manual work.
                  </p>
                </div>
                <div className="square" >
                  <h3>Cost-Effective Solutions</h3>
                  <p className="pharagraph">
                  We develop exponential Fintech projects within the business budget of newbie startups and entrepreneurs. You can also tailor or customize your projects depending on your business needs and requirements.
                  </p>
                </div>
                <div className="square" >
                  <h3>Global Outreach</h3>
                  <p className="pharagraph">
                  Our Fintech software development will help you to get access all over the world. You can digitalize the world with our Fintech software solutions regardless of the geographical regions and jurisdictions.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Benificial
