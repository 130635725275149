import React from 'react'


const FeatureBenefits = () => {


  return (
    <section className="what-make tokenize fintech-2 pt-100 mb-0">   
          <div className="container">
            <h2 className="main-heading text-center"><span className='bluecolor'>Our Add-On Features </span> For Fintech Software Development</h2>
            <p className='text-center'>Explore the additional features of our fintech software development services to elevate your business.</p>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Dynamic KYC Platform</p>
                  <p>Account Verification and Management</p>
                  <p>Cross-Platform Functionality</p>
                  <p>Custom Reporting</p>
                  <p>Multiple Authentication Systems</p>
                  <p>Secure Hosting</p>
              </div>
          </div>
      </section>
  )
}

export default FeatureBenefits